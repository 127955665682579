import { Route, Switch } from "react-router-dom"
import Home from "../../../view/home/views"
import Information from "../../../view/information/views"
import TestCenter from "../../../view/testcenter/views"
import Registration from "../../../view/registration/views"
import OrderVoucher from "../../../view/ordervoucher/views"
import ContactUs from "../../../view/contactus/views"
import CheckOrder from "../../../view/checkorder/views"

const LayoutRouterComponent = () => {
    return (
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/information" component={Information} />
            <Route path="/testcenter" component={TestCenter} />
            <Route path="/registration" component={Registration} />
            <Route path="/order" component={OrderVoucher} />
            <Route path="/contact" component={ContactUs} />
            <Route path="/check" component={CheckOrder} />
        </Switch>
    )
}

export default LayoutRouterComponent
