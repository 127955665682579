import { Component } from "react"
import { QRCode } from "react-qrcode-logo"
import LogoIief from "../../../assets/images/iief.png"
import LogoEts from "../../../assets/images/logo.png"
import QrisName from "../../../assets/images/banks/qris-name.png"

import Shimmer from "react-shimmer-effect"
import IndexBloc from "../blocs/index-bloc"
import apiResponse from "../../../services/apiResponse"

class CheckOrderView extends Component {
    indexBloc = new IndexBloc()

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            orderData: null,
            orderCode: "",
            submited: false,
        }
    }

    checkOrder = () => {
        this.indexBloc.fetchOrder({ code: this.state.orderCode })
    }

    componentDidMount() {
        this.indexBloc.orderChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    this.setState({
                        loading: false,
                    })
                    break
                case apiResponse.LOADING:
                    this.setState({
                        submited: true,
                        orderData: null,
                        loading: true,
                    })
                    break
                case apiResponse.COMPLETED:
                    const response = result.data.results
                    this.setState({
                        orderCode: "",
                        orderData: response,
                        loading: false,
                    })
                    break
                case apiResponse.ERROR:
                    this.setState({
                        orderData: null,
                        loading: false,
                    })
                    break
                default:
                    break
            }
        })
    }

    componentWillUnmount() {
        this.indexBloc.orderChannel.unsubscribe()
    }

    render() {
        return (
            <div className="relative mx-auto min-h-[200px] w-full rounded-2xl bg-white px-6 py-5 shadow-2xl">
                <div className="flex">
                    <div className="relative bottom-0 flex h-12 w-full">
                        <div className="absolute ms-3 h-10 w-[230px] flex-1 skew-x-[-22deg] transform border-2 border-iief-orange"></div>
                        <div className="absolute mt-2 h-10 w-[230px] flex-1 skew-x-[-22deg] transform bg-iief-green text-xs drop-shadow">
                            <div className="text-md flex skew-x-[22deg] transform items-center justify-center gap-2 pe-12">
                                <div className="mb-6 px-6 pt-[7px] text-xl font-bold text-white">
                                    Check Order
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ck-content mb-5 mt-2 bg-slate-100 p-4 font-semibold">
                    <div className="mb-1 text-center text-sm font-bold text-iief-green">
                        <input
                            className="focus:shadow-outline mt-2 w-full rounded border px-3 py-2 text-center text-sm text-gray-900 focus:outline-none sm:max-w-[300px]"
                            type="text"
                            maxLength={16}
                            placeholder="Order Code"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    this.checkOrder()
                                    //e.target.value = ""
                                }
                            }}
                            onKeyUp={(e) => {
                                this.setState({
                                    orderCode: e.target.value,
                                })
                            }}
                        />
                    </div>
                </div>

                {!this.state.loading ? (
                    <>
                        {this.state.orderData !== null ? (
                            <>
                                {this.state.orderData.status === 0 && (
                                    <div className="text-center text-red-500">
                                        Order code is expired
                                    </div>
                                )}
                                {this.state.orderData.status === 1 && (
                                    <>
                                        {this.state.orderData.payment ===
                                        "qris" ? (
                                            <div className="flex grow">
                                                <div className="grow" />
                                                <div className="mb-2 mt-2 w-[284px] grow-0 rounded border bg-white p-4 drop-shadow-lg">
                                                    <div className="mb-2 flex justify-between">
                                                        <img
                                                            className="h-[18px]"
                                                            src={QrisName}
                                                        />
                                                        <img
                                                            className="h-[18px]"
                                                            src={LogoEts}
                                                        />
                                                    </div>
                                                    <div className="text-md mb-2 text-center font-bold text-gray-500">
                                                        {
                                                            this.state.orderData
                                                                .code
                                                        }
                                                    </div>
                                                    <QRCode
                                                        value={
                                                            this.state.orderData
                                                                .number
                                                        }
                                                        logoImage={LogoIief}
                                                        logoPadding={4}
                                                        logoWidth={30}
                                                        logoHeight={30}
                                                        quietZone={0}
                                                        removeQrCodeBehindLogo
                                                        size={250}
                                                        fgColor="#445d6e"
                                                        eyeColor={{
                                                            outer: "#087f83",
                                                            inner: "#ec7224",
                                                        }}
                                                    />
                                                    <div className="mt-2 text-center text-sm font-bold text-ets-green">
                                                        Waiting payment...
                                                    </div>
                                                    <div className="mb-2 text-center text-lg font-bold text-iief-orange">
                                                        Rp
                                                        {this.state.orderData.amount
                                                            .toString()
                                                            .replace(".", ",")
                                                            .toString()
                                                            .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ".",
                                                            )}
                                                    </div>
                                                    <div className="rounded bg-iief-green py-2 text-center text-[10px] font-bold text-white">
                                                        <div>
                                                            This QR code is only
                                                            for one-time
                                                            transaction
                                                        </div>
                                                        <div className="font-normal">
                                                            Valid until{" "}
                                                            {
                                                                this.state
                                                                    .orderData
                                                                    .date
                                                            }{" "}
                                                            WIB
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="grow" />
                                            </div>
                                        ) : (
                                            <>
                                                <div className="my-4 mb-8">
                                                    <div className="border-b-2 pb-2 font-bold text-slate-500">
                                                        <img
                                                            className="h-[44px]"
                                                            src={
                                                                this.state
                                                                    .orderData
                                                                    .logo
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-10">
                                                    <div className="ms-8 pb-2 text-sm text-gray-600 sm:flex">
                                                        <div className="w-52">
                                                            Order Code
                                                        </div>
                                                        <div className="font-bold text-iief-green">
                                                            {
                                                                this.state
                                                                    .orderData
                                                                    .code
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="ms-8 pb-2 text-sm text-gray-600 sm:flex">
                                                        <div className="w-52">
                                                            Virtaul Account
                                                            Number
                                                        </div>
                                                        <div className="text-iief-green">
                                                            {
                                                                this.state
                                                                    .orderData
                                                                    .number
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="ms-8 pb-2 text-sm text-gray-600 sm:flex">
                                                        <div className="w-52">
                                                            Nominal
                                                        </div>
                                                        <div className="text-iief-green">
                                                            Rp
                                                            {this.state.orderData.amount
                                                                .toString()
                                                                .replace(
                                                                    ".",
                                                                    ",",
                                                                )
                                                                .toString()
                                                                .replace(
                                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                                    ".",
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className="ms-8 pb-2 text-sm text-gray-600 sm:flex">
                                                        <div className="w-52">
                                                            Status
                                                        </div>
                                                        <div className="text-iief-green">
                                                            Waiting payment...
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.orderData.step
                                                    .length > 0 && (
                                                    <div className="my-4 mb-8">
                                                        <div className="border-b-2 pb-2 font-bold text-slate-500">
                                                            <div className="grow-none text-md">
                                                                How to Pay?
                                                            </div>
                                                        </div>
                                                        {this.state.orderData.step.map(
                                                            (data, _) => {
                                                                return (
                                                                    <>
                                                                        <div className="mt-3 bg-slate-100 px-4 py-2 font-semibold">
                                                                            <div className="text-sm text-iief-green">
                                                                                {
                                                                                    data.payment_step_name
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="prose px-4 pb-4 text-xs"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: data.payment_step_data,
                                                                            }}
                                                                        />
                                                                    </>
                                                                )
                                                            },
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                                {this.state.orderData.status === 2 && (
                                    <div className="text-center text-green-800">
                                        Order code has been paid
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {this.state.submited && (
                                    <div className="text-center text-red-500">
                                        Order code not found
                                    </div>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <div className="mx-auto flex flex-col pb-10 pt-10">
                        <div className="mb-1 text-xs text-gray-500">
                            <Shimmer>
                                <div
                                    className="rounded"
                                    style={{
                                        width: "80%",
                                        height: 12,
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                    }}
                                ></div>
                            </Shimmer>
                        </div>
                        <div className="mb-1 text-xs text-gray-500">
                            <Shimmer>
                                <div
                                    className="rounded"
                                    style={{
                                        width: "80%",
                                        height: 12,
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                    }}
                                ></div>
                            </Shimmer>
                        </div>
                        <div className="mb-1 text-xs text-gray-500">
                            <Shimmer>
                                <div
                                    className="rounded"
                                    style={{
                                        width: "40%",
                                        height: 12,
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                    }}
                                ></div>
                            </Shimmer>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default CheckOrderView
