import { apiWrapper, apiCancel } from "../wrapper"

export const initial = "base"
//const baseUrl = "http://localhost/2023/iief/sales/api/kiosk/v1/"
//const baseUrl = "https://api.iieflounge.id/kiosk/v1/"
const baseUrl = "https://api-dev.iieflounge.id/kiosk/v1/"
const appCode = "d1921aa0ca3c1146a01520c04e6caa9e"
const outputType = "json"

export const connect = "connect/"
export const getAccessToken = "getAccessToken/"
export const getConfig = "getConfig/"

//Check
export const getOrderCode = "getOrderCode/"

//Information
export const getInformationList = "getInformationList/"
export const getInformationContent = "getInformationContent/"

//Test Center
export const getTestCenterList = "getTestCenterList/"

//Contact Us
export const getContactUsData = "getContactUsData/"

//Registration
export const getRegistrationContent = "getRegistrationContent/"

//Slider
export const getSliderList = "getSliderList/"

//Order Voucher
export const getOrderProducts = "getOrderProducts/"
export const checkOrderDiscount = "checkOrderDiscount/"
export const getPaymentMethod = "getPaymentMethod/"
export const submitOrder = "submitOrder/"

export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (
    method,
    route,
    data = { params: null, body: null, cancelToken: null },
    needToken = true,
) => {
    if (needToken) {
        const thisTime =
            Math.floor(Date.now() / 1000) -
            (localStorage.getItem("localTime") -
                localStorage.getItem("serverTime"))
        const expire = localStorage.getItem(`${initial}AccessExpired`)
        if (expire < thisTime && route !== "auth/connect/") {
            await apiWrapper(
                baseUrl,
                initial,
                appCode,
                outputType,
                "post",
                "getAccessToken/",
                data,
            )
                .then((result) => {
                    localStorage.setItem(
                        "localTime",
                        Math.floor(new Date().getTime() / 1000),
                    )
                    localStorage.setItem("serverTime", result.serverTime)
                    localStorage.setItem(
                        initial + "AccessToken",
                        result.results.access_token,
                    )
                    return apiWrapper(
                        baseUrl,
                        initial,
                        appCode,
                        outputType,
                        method,
                        route,
                        data,
                    )
                })
                .catch(() => {
                    return Promise.reject("Tidak dapat mengambil accessToken")
                })
        } else {
            return apiWrapper(
                baseUrl,
                initial,
                appCode,
                outputType,
                method,
                route,
                data,
            )
        }
    }
    return apiWrapper(
        baseUrl,
        initial,
        appCode,
        outputType,
        method,
        route,
        data,
    )
}
