import { Subject } from "rxjs"
import apiResponse from "../../../services/apiResponse"
import IndexRepository from "../repositories/index-repository"

class IndexBloc {
    orderChannel = new Subject()
    repository = new IndexRepository()

    constructor() {
        this.orderChannel.next({ status: apiResponse.INITIAL })
    }

    fetchOrder = async (query) => {
        this.orderChannel.next({ status: apiResponse.LOADING })
        try {
            await this.repository
                .fetchOrder(query)
                .then((result) => {
                    if (result.status) {
                        this.orderChannel.next({
                            status: apiResponse.COMPLETED,
                            data: result,
                        })
                    } else {
                        this.orderChannel.next({
                            status: apiResponse.ERROR,
                            data: result.message,
                        })
                    }
                })
                .catch((error) => {
                    this.orderChannel.next({
                        status: apiResponse.ERROR,
                        data: error,
                    })
                })
        } catch (error) {
            console.log("rxjs", "Channel orderChannel Unsubscribed")
        }
    }
}

export default IndexBloc
